export enum NavigationRoutes {
  // Root level and general routes
  Store = '/',
  Guides = '/guides',
  Search = '/search',
  Cart = '/cart',
  ContactUs = '/contact-us',
  NotFound = '/404',
  Notifications = '/notifications',
  Login = '/login',
  OtpVerification = '/verify',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',

  // Profile-related routes
  Profile = '/profile',
  ProfileDelete = '/profile/delete',
  ProfileEdit = '/profile/edit',
  ProfileEditEmail = '/profile/edit/email',
  ProfileEditPassword = '/profile/edit/password',
  ProfileEditPhone = '/profile/edit/phone',
  ProfileSettingNotification = '/profile/settings/notification',
  Devices = '/profile/devices',
  AddToHomeScreen = '/profile/add-to-home-screen',
  WalletMutationHistory = '/profile/kuro-point-histories',

  // eSIM-related routes
  MySim = '/esims',
  Esim = '/esims/[id]',
  EsimActivation = '/esims/[id]/share',
  EsimAddSubscription = '/esims/[id]/add-subscriptions',
  EsimAllPlans = '/esims/[id]/all-plans',
  EsimHistory = '/esims/[id]/history',
  EsimInstallGuide = '/esims/[id]/installation-guides',
  EsimShare = '/esims/[id]/share',
  EsimUpcoming = '/esims/[id]/upcoming',
  EsimViewSubscriptions = '/esims/[id]/view-subscriptions',
  CustomizeDesign = '/esims/[id]/customize-design',
  Recharge = '/esims/[id]/recharge',
  ArchiveEsim = '/archived-esims',

  // eSIM public routes
  EsimPublic = '/p/esims/[id]',
  EsimPublicInstall = '/p/esims/[id]/installation-guides',

  // Purchase-related routes
  Purchase = '/store/esims/[slug]',
  PurchaseAboutEsim = '/store/esims/[slug]/about-esim',
  PurchasePlanDetails = '/store/esims/[slug]/plan-details',

  // Referral and affiliate routes
  Referral = '/referral',
  ReferralBind = '/referral/bind',
  ReferralPerformance = '/referral/[id]/performance',
  AffiliateRegistration = '/affiliate/join',
  AffiliateAccountSettings = '/affiliate/settings',

  // Payment and checkout routes
  CheckoutCart = '/checkout-cart',
  Checkout = '/checkout',
  Payment = '/payment/[id]',
  PaymentSettle = '/payment/[id]/settle',
  PaymentStatus = '/payment-status/[id]',
  XenditPayment = '/xendit-payment',

  // Order and plan details routes
  Orders = '/orders',
  OrderDetail = '/orders/[id]',
  PlanDetail = '/cart/plan-detail/[id]',

  // Payment method routes
  NewPaymentCard = '/payment-method/cards/new',
  AllPaymentCards = '/payment-method/cards',
  ViewPaymentCard = '/payment-method/cards/[id]',

  // Subscription routes
  AllSubscriptions = '/subscriptions',
  ViewSubscription = '/subscriptions/[id]',
  NewSubscription = '/subscriptions/[id]/new',

  // Registration-related routes
  RegisterAppTracking = '/register/app-tracking',
  RegisterCompleteProfile = '/register/complete-profile',
  RegisterPushNotifications = '/register/push-notifications',
  __RegisterRoutePrefix = '/register',
}

export enum NavigationSiteRoutes {
  RefundPolicy = '/refund-policy',
  TermsOfService = '/terms-of-service',
  PrivacyPolicy = '/privacy-policy',
}
